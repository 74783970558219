

// Scripts
import jquery from './import-jquery';
import kursor from 'kursor';
import "../vendor/royal-slider/jquery.royalslider.custom.min.js";

// Style
import "../scss/layout.scss";

jquery(($) => {
    new kursor({
        color: '#e10020',
        type: 1,
        removeDefaultCursor: true
    });

    
    $('.royalSlider').royalSlider({
        width: '100%',
        height: '100%',
        keyboardNavEnabled: true,
        imageScaleMode: 'fill',
        arrowsNav: true,
        controlNavigation: 'arrows'
    });  

    var slider = $('.royalSlider');
    slider.prepend(slider.find('.rsNav'));
});

